@import './font';
@import './theme';

@import 'primeng/resources/primeng.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

$primaryColor: #222222 !default;
$primaryTextColor: #ffffff !default;
$accentColor: #008BB3 !default;
$accentTextColor: #ffffff !default;

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
}

.p-button-label {
  text-transform: uppercase;
}

.p-button.p-button-raised:enabled:focus {
  box-shadow: inset 0 0 0 1px;
}
